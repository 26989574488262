import React, { useEffect, useState } from "react";
import Loader from "../../../components/shared/Loader";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../../store/actions/metaAction";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../../api/server";
import axios from "axios";
import Cookies from "js-cookie";

const BlogCategoriesShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();

	const {
		params: { blogCategoryID },
	} = match;

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة نوع جديدة",
				breadcrumb: "الانواع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		server(Cookies)
			.get(`/categories/${blogCategoryID}`)
			.then(data => {
				setValue("title", data.data.data.category.title);
			});
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const dataResponse = await server(cookie).put(
				`/categories/${blogCategoryID}`,
				{
					...data,
					description: {
						ar: "",
						en: "",
					},
				}
			);
			reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameAr">اسم الفئة باللغة العربية</label>
									<input
										type="text"
										name="name"
										id="nameAr"
										{...register("title.ar", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">اسم الفئة بالغة الانجليزية</label>
									<input
										type="text"
										name="name"
										id="nameEn"
										{...register("title.en", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12">
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default BlogCategoriesShow;
