import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";

const PartnersIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع الشركاء",
				breadcrumb: "الشركاء",
				button: {
					permissions: ["post-create"],
					show: true,
					text: "اضافة شريك جديد",
					link: "/partners/create",
				},
			})
		);

		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/Partners`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				setData(response.data.data.page.sections);
				setLoading(false);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
				setLoading(false);
			});
	};

	const handleDelete = postID => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/pages/Partners/sections/${postID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(function (response) {
				onStart();
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	return (
		<section>
			{loading && <Loader />}

			<div className="row">
				{data &&
					data?.map(item => (
						<div key={item.slug} className="col-md-4">
							<div
								className="employees"
								style={{
									width: "100%",
									aspectRatio: "1",
									border: "1px solid #eee",
								}}
							>
								<button onClick={() => handleDelete(item.slug)}>
									<i className="fas fa-trash"></i>
								</button>
								<Link to={`/partners/show/${item.slug}`}>
									<img
										src={
											item.image && item.image.blob
												? item.image.path
												: `${process.env.REACT_APP_MAIN_URL}/${
														item.image.path ?? ""
												  }`
										}
										alt={item.image ?? ""}
										style={{ objectFit: "contain" }}
									/>
								</Link>
							</div>
						</div>
					))}
			</div>
		</section>
	);
};

export default PartnersIndex;
