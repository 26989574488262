let id = 1;
const randomId = () => {
	id++;
	return id;
};

export const navbarItems = [
	{
		id: randomId(),
		link: "/",
		icon: "fas fa-home",
		title: "لوحة التحكم",
		children: [],
	},
	{
		id: randomId(),
		link: "/moderators/index",
		icon: "fas fa-users-cog",
		title: "المشرفين",
		children: [
			{
				id: randomId(),
				title: "جميع المشرفين",
				link: "/moderators/index",
			},
			{
				id: randomId(),
				title: "اضافة مشرف جديد",
				link: "/moderators/create",
			},
		],
	},

	{
		id: randomId(),
		link: "/projects/index",
		icon: "fas fa-solid fa-building",
		title: "المشاريع",
		children: [
			{
				id: randomId(),
				title: "جميع المشاريع",
				link: "/projects/index",
			},
			{
				id: randomId(),
				title: "اضافة مشروع جديدة",
				link: "/projects/create",
			},
			{
				id: randomId(),
				title: "جميع الفئة",
				link: "/category/index",
			},
			{
				id: randomId(),
				title: "اضافة الفئة جديدة",
				link: "/category/create",
			},
		],
	},

	{
		id: randomId(),
		link: "/services/index",
		icon: "fas fa-solid fa-laptop-house",
		title: "الخدمات",
		children: [
			{
				id: randomId(),
				title: "جميع الخدمات",
				link: "/services/index",
			},
			{
				id: randomId(),
				title: "اضافة خدمة جديدة",
				link: "/services/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/partners",
		title: "شركاء النجاح",
		icon: "fas fa-solid fa-people-carry",
		children: [
			{
				id: randomId(),
				title: "  جميع شركاء النجاح",
				link: "/partners/index",
			},
			{
				id: randomId(),
				title: " شريك نجاح جديد",
				link: "/partners/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/comments/index",
		icon: "fas fa-comments",
		title: "اراء العملاء",
		children: [
			{
				id: randomId(),
				title: "جميع الاراء",
				link: "/comments/index",
			},
			{
				id: randomId(),
				title: "اضافة رائ جديدة",
				link: "/comments/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/blogs/index",
		icon: "fas fa-blog",
		title: "المدونة",
		children: [
			{
				id: randomId(),
				title: "جميع المدونات",
				link: "/blogs/index",
			},
			{
				id: randomId(),
				title: "اضافة مدونة جديدة",
				link: "/blogs/create",
			},
			{
				id: randomId(),
				title: "جميع فئات المدونات",
				link: "/blogs/categories/index",
			},
			{
				id: randomId(),
				title: "اضافة فئة مدونة جديدة",
				link: "/blogs/categories/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/orders/index",
		icon: "fas fa-envelope",
		title: "الطلبات",
		children: [],
	},
	{
		id: randomId(),
		link: "/social",
		title: "وسائل التواصل",
		icon: "fas fa-id-card",
		children: [
			{
				id: randomId(),
				title: " انواع التواصل",
				link: "/social/socialType/index",
			},
			{
				id: randomId(),
				title: "اضافة نوع تواصل",
				link: "/social/socialType/create",
			},
			{
				id: randomId(),
				title: " روابط التواصل",
				link: "/social/links/index",
			},
			{
				id: randomId(),
				title: "اضافة رابط تواصل",
				link: "/social/links/create",
			},
		],
	},

	{
		id: randomId(),
		link: "/times/index",
		icon: "fas fa-clock",
		title: "مواعيد العمل",
		children: [
			{
				id: randomId(),
				title: "جميع المواعيد",
				link: "/times/index",
			},
			{
				id: randomId(),
				title: "اضافة موعد جديد",
				link: "/times/create",
			},
		],
	},
	{
		id: randomId(),
		link: "/siteinfo",
		icon: "far fa-address-card",
		title: " بيانات الموقع ",
		children: [
			{
				id: randomId(),
				title: "لافتات الترحيب",
				link: "/siteinfo/heroSection/index",
			},
			{
				id: randomId(),
				title: "عن داما",
				link: "/siteinfo/aboutUs/show/about-us",
			},
			{
				id: randomId(),
				title: "قسم عنوان الصفحة",
				link: "/siteinfo/breadcrumb/index",
			},
			// {
			// 	id: randomId(),
			// 	title: "البيانات الاضافيه",
			// 	link: "/aboutPage/sideinfo/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "المميزات",
			// 	link: "/aboutPage/advantages/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "الاحصائيات",
			// 	link: "/aboutPage/statistics/index",
			// },
			// {
			// 	id: randomId(),
			// 	title: "الأسئله الشائعه",
			// 	link: "/aboutPage/questions/index",
			// },
		],
	},
];
