import { BrowserRouter as Router, Switch } from "react-router-dom";
import GeustRoute from "./helpers/authentication/geustRoute";
import Layout from "./components/layout/Layout";
import AuthRoute from "./helpers/authentication/authRoute";
import Login from "./views/authentication/Login";
import Home from "./views/Home";
import ModeratorsIndex from "./views/moderators/ModeratorsIndex";
import ModeratorsCreate from "./views/moderators/ModeratorsCreate";
import ModeratorShow from "./views/moderators/ModeratorShow";

import ProjectIndex from "./views/projects/ProjectIndex";
import ProjectCreate from "./views/projects/ProjectCreate";
import ProjectShow from "./views/projects/ProjectShow";

import CategoriesIndex from "./views/category/CategoriesIndex";
import CategoriesCreate from "./views/category/CategoriesCreate";
import CategoriesShow from "./views/category/CategoriesShow";

import TypeIndex from "./views/apartmentType/TypeIndex";
import TypeCreate from "./views/apartmentType/TypeCreate";
import TypeShow from "./views/apartmentType/TypeShow";
import WhyUsShow from "./views/whyUs/WhyUsShow";
import WhyUsIndex from "./views/whyUs/WhyUsIndex";

import ServicesApplications from "./views/services/ServicesApplications";
import ServiceShowApplication from "./views/services/ServiceShowApplication";

import ContactIndex from "./views/contact/ContactIndex";
import ContactShow from "./views/contact/ContactShow";
import SliderIndex from "./views/slider/SliderIndex";
import SliderCreate from "./views/slider/SliderCreate";
import SliderShow from "./views/slider/SliderShow";

// import LinksIndex from "./views/footerLinks/LinksIndex";
// import LinksCreate from "./views/footerLinks/LinksCreate";
// import LinksShow from "./views/footerLinks/LinksShow";

// import About from "./views/About";
import Settings from "./views/Settings";

import CommentsIndex from "./views/Comments/CommentsIndex";
import CommentsCreate from "./views/Comments/CommentsCreate";
import CommentsShow from "./views/Comments/CommentsShow";
import HeroSectionIndex from "./views/heroSection/HeroSectionIndex";
import HeroSectionCreate from "./views/heroSection/HeroSectionCreate";
import HeroSectionShow from "./views/heroSection/HeroSectionShow";
import AboutUsCreate from "./views/aboutPage/AboutUsCreate";
import AboutUsIndex from "./views/aboutPage/AboutUsIndex";
import AboutUsShow from "./views/aboutPage/AboutUsShow";
import LinksCreate from "./views/socials/links/LinksCreate";
import LinksIndex from "./views/socials/links/LinksIndex";
import SocialTypes from "./views/socials/types/SocialTypesIndex";
import SocialTypesCreate from "./views/socials/types/SocialTypesCreate";
import LinksShow from "./views/socials/links/LinksShow";
import PartnersCreate from "./views/partners/PartnersCreate";
import PartnersIndex from "./views/partners/PartnersIndex";
import PartnersShow from "./views/partners/PartnersShow";
import BlogCategoriesIndex from "./views/blogs/categories/BlogCategoriesIndex";
import BlogCategoriesCreate from "./views/blogs/categories/BlogCategoriesCreate";
import BlogCategoriesShow from "./views/blogs/categories/BlogCategoriesShow";
import BlogsIndex from "./views/blogs/BlogsIndex";
import BlogsCreate from "./views/blogs/BlogsCreate";
import BlogsShow from "./views/blogs/BlogsShow";
import ServicesIndex from "./views/services/ServicesIndex";
import ServicesCreate from "./views/services/ServicesCreate";
import ServicesShow from "./views/services/ServicesShow";

import TimesIndex from "./views/workTimes/TimesIndex";
import TimesCreate from "./views/workTimes/TimesCreate";
import TimesShow from "./views/workTimes/TimesShow";
import BreadcrumbIndex from "./views/breadcrumb";

function App() {
	return (
		<Router>
			<Switch>
				<GeustRoute path="/login" component={Login} />
				<Layout>
					<AuthRoute path="/" exact component={Home} />
					<AuthRoute path="/dashboard" component={Home} />
					<AuthRoute path="/moderators/index" component={ModeratorsIndex} />
					<AuthRoute path="/moderators/create" component={ModeratorsCreate} />
					<AuthRoute
						path="/moderators/show/:moderatorID"
						component={ModeratorShow}
					/>
					<AuthRoute
						path="/social/socialType/index"
						component={SocialTypes}
						exact
					/>
					<AuthRoute
						path="/social/socialType/create"
						component={SocialTypesCreate}
						exact
					/>
					<AuthRoute path="/social/links/index" component={LinksIndex} />
					<AuthRoute path="/social/links/create" component={LinksCreate} />
					<AuthRoute path="/social/links/show/:linkId" component={LinksShow} />
					<AuthRoute path="/category/index" component={CategoriesIndex} />
					<AuthRoute path="/category/create" component={CategoriesCreate} />
					<AuthRoute
						path="/category/show/:categoryId"
						component={CategoriesShow}
					/>

					<AuthRoute path="/projects/index" component={ProjectIndex} />
					<AuthRoute path="/projects/create" component={ProjectCreate} />
					<AuthRoute path="/projects/show/:projectId" component={ProjectShow} />

					<AuthRoute path="/services/index" component={ServicesIndex} />
					<AuthRoute path="/services/create" component={ServicesCreate} />
					<AuthRoute
						path="/services/show/:serviceID"
						component={ServicesShow}
					/>
					<AuthRoute
						path="/services/applications"
						component={ServicesApplications}
					/>
					<AuthRoute
						path="/services/application/:applicationID"
						component={ServiceShowApplication}
					/>
					<AuthRoute path="/orders/index" component={ContactIndex} />
					<AuthRoute path="/orders/show/:contactID" component={ContactShow} />
					<AuthRoute path="/settings" component={Settings} />
					<AuthRoute path="/slider/index" component={SliderIndex} />
					<AuthRoute path="/slider/create" component={SliderCreate} />
					<AuthRoute path="/slider/show/:slideID" component={SliderShow} />
					<AuthRoute path="/footerLinks/index" component={LinksIndex} />
					<AuthRoute path="/footerLinks/create" component={LinksCreate} />
					{/* <AuthRoute path="/footerLinks/show/:linkID" component={LinksShow} /> */}
					{/* Comments Section */}
					<AuthRoute path="/Comments/index" component={CommentsIndex} />
					<AuthRoute path="/Comments/create" component={CommentsCreate} />
					<AuthRoute path="/Comments/show/:centerID" component={CommentsShow} />
					{/* Comments End Here */}
					<AuthRoute path="/blogs/index" component={BlogsIndex} />
					<AuthRoute path="/blogs/create" component={BlogsCreate} />
					<AuthRoute path="/blogs/show/:blogID" component={BlogsShow} />
					<AuthRoute
						path="/blogs/categories/index"
						component={BlogCategoriesIndex}
					/>
					<AuthRoute
						path="/blogs/categories/create"
						component={BlogCategoriesCreate}
					/>
					<AuthRoute
						path="/blogs/categories/show/:blogCategoryID"
						component={BlogCategoriesShow}
					/>
					<AuthRoute path="/partners/index" component={PartnersIndex} />
					<AuthRoute path="/partners/create" component={PartnersCreate} />
					<AuthRoute
						path="/partners/show/:partnerID"
						component={PartnersShow}
					/>
					<AuthRoute
						path="/siteinfo/heroSection/create"
						component={HeroSectionCreate}
					/>
					<AuthRoute
						path="/siteinfo/heroSection/index"
						component={HeroSectionIndex}
					/>
					<AuthRoute
						path="/siteinfo/heroSection/show/:heroSectionId"
						component={HeroSectionShow}
					/>
					<AuthRoute
						path="/siteinfo/breadcrumb/index"
						component={BreadcrumbIndex}
					/>
					<AuthRoute
						path="/siteinfo/aboutUs/create"
						component={AboutUsCreate}
					/>
					<AuthRoute path="/siteinfo/aboutUs/index" component={AboutUsIndex} />
					<AuthRoute
						path="/siteinfo/aboutUs/show/:aboutUsSectionId"
						component={AboutUsShow}
					/>
					{/* work times Section */}
					<AuthRoute path="/times/index" component={TimesIndex} />
					<AuthRoute path="/times/create" component={TimesCreate} />
					<AuthRoute path="/times/show/:centerID" component={TimesShow} />
				</Layout>
			</Switch>
		</Router>
	);
}

export default App;
