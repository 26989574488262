import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";

const PartnersCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [picture, setPicture] = useState(userPlaceholder);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة شريك جديد",
				breadcrumb: "الشركاء",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
	};

	const handlePicture = () => {
		const fileInput = document.querySelector("#picture");
		setPicture(URL.createObjectURL(fileInput.files[0]));
	};

	const handleSubmit = e => {
		e.preventDefault();
		const picture = document.querySelector("#picture");
		if (picture.files[0]) {
			setLoading(true);
			const data = new FormData();
			data.append("file", picture.files[0]);
			axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, data, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(response => {
					const data = {
						section: {
							title: "Partner",
							image: {
								path: response.data.data.file.path,
								blob: false,
							},
						},
					};
					axios
						.post(
							`${process.env.REACT_APP_API_URL}/pages/Partners/sections`,
							{
								...data,
							},
							{
								headers: {
									Authorization: `Bearer ${cookie.get("token")}`,
									Accept: "application/json",
								},
							}
						)
						.then(function (response) {
							history.push("/partners/index");
							toast.success("تم اضافة الشريك بنجاح!");
							setLoading(false);
						})
						.catch(function (error) {
							toast.error("خطأ، برجاء المحاولة مره اخرى!");
							setLoading(false);
						});
				})
				.catch(error => {
					console.log(error);
					toast.error("خطأ في رفع الصورة، برجاء المحاولة مرة أخرى!");
					setLoading(false);
				});
		} else {
			toast.error("برجاء اختيار الصورة أولا!");
		}
	};

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className="col-md-5 mx-auto">
						<div className="form-item">
							<input type="file" onChange={handlePicture} id="picture" />
							<img src={picture} alt="avatar" className="imagePreview" />
						</div>
						<div className="form-item">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>
			</form>
		</section>
	);
};

export default PartnersCreate;
