import React, { Fragment, useEffect, useReducer, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server from "../../api/server";
import axios from "axios";
import { Input, Select } from "../../components/shared/FormComponents";
import { toast } from "react-toastify";
import { v4 } from "uuid";

const BlogsShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		reset,
		resetField,
	} = useForm({
		defaultValues: {
			faqs: [],
			tags: [],
		},
	});
	const faqs = useFieldArray({
		control,
		name: "faqs",
	});
	const tags = useFieldArray({
		control,
		name: "tags",
	});
	const {
		params: { blogID },
	} = match;

	// initial states
	const [category, setCategory] = useState([]);

	// form Needed states
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	const onStart = async () => {
		setLoading(true);
		dispatch(
			updateMeta({
				title: "اضافة مدونة جديدة",
				breadcrumb: "المدونات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		await Promise.all([
			await server(cookie).get(`/blogs/${blogID}`),
			await server(cookie).get("/categories"),
		])
			.then(data => {
				setValue("category", data[0]?.data?.data?.blogObj?.category?.id);
				setValue("title", data[0]?.data?.data?.blogObj?.title);
				setValue("description", data[0]?.data?.data?.blogObj?.description);
				setValue("image", data[0]?.data?.data?.blogObj?.image);
				setValue(
					"tags",
					data[0]?.data?.data?.blogObj?.tags?.map(tag => ({ value: tag }))
				);
				setValue("faqs", data[0]?.data?.data?.blogObj?.contents?.faqs);
				setValue("author", data[0]?.data?.data?.blogObj?.contents?.author);
				setValue("releaseDate", data[0]?.data?.data?.blogObj?.contents?.date);
				setCategory([
					...data[1]?.data?.data?.categories?.map(data => ({
						name: data?.title?.ar,
						slug: data?.id,
					})),
				]);
			})
			.catch(error => toast.error(error));
		setLoading(false);
	};

	// handleMethods

	const handlePicture = async () => {
		const tempValue = getValues(`pictureUrl`);
		if (tempValue.length < 1) return;
		setLoading(true);
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		const formData = new FormData();
		formData.append(`file`, tempValue?.[0]);
		setLoading(false);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setValue(`image`, responseImage.data.data.file.path);
				setValue(`pictureUrl`, blobUrl);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
		forceUpdate();
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const submitHandler = async data => {
		setLoading(true);
		try {
			const { tag, pictureUrl, releaseDate, faqs, author, ...finalData } = {
				...data,
				name: `${data?.title?.en} blog`,
				tags: data?.tags.map(tag => tag.value),
				contents: {
					author: data?.author,
					date: data.releaseDate,
					faqs: data.faqs,
				},
			};
			const dataResponse = await server(cookie).put(`/blogs/${blogID}`, {
				...finalData,
			});
			reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
		register("image", { required: true });
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<div className="card">
				<form onSubmit={handleSubmit(submitHandler)}>
					<div className="row">
						<div className="row">
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="category">اختار الفئة</label>
										<Select
											name="category"
											id="category"
											options={[...category]}
											register={register}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="author">اسم الكاتب</label>
										<Input
											type="text"
											register={register}
											id="author"
											name="author"
											required={true}
										/>
									</div>
								</div>
							</div>

							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="nameAr">اسم باللغة العربية</label>
										<Input
											type="text"
											name="title.ar"
											id="nameAr"
											register={register}
											required={true}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="nameEn">اسم بالغة الانجليزية</label>
										<Input
											type="text"
											name="title.en"
											id="nameEn"
											register={register}
											required={true}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="descriptionAr">الوصف بالغة العربية</label>
										<textarea
											rows={5}
											type="text"
											name="descriptionAr"
											id="descriptionAr"
											{...register(`description.ar`, { required: true })}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="descriptionEn">
											الوصف بالغة الانجليزية
										</label>
										<textarea
											rows={5}
											type="text"
											name="descriptionEn"
											id="descriptionEn"
											{...register(`description.en`, { required: true })}
										/>
									</div>
								</div>
							</div>

							<div className="col-md-8">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="picture">البانر</label>
										<input
											type="file"
											id="picture"
											{...register("pictureUrl", {
												onChange: () => handlePicture(),
											})}
										/>
										<img
											src={
												getValues("image") == null || getValues("image") === ""
													? userPlaceholder
													: `${process.env.REACT_APP_MAIN_URL}/${getValues(
															"image"
													  )}`
											}
											alt="avatar"
											className="imagePreview"
										/>
									</div>
								</div>
							</div>
							<div className="col-md-4">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="releaseDate">تاريخ الاعلان</label>
										<Input
											type="date"
											name="releaseDate"
											id="releaseDate"
											register={register}
											required={true}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-8">
										<div className="card-body">
											<div className="form-item">
												<label htmlFor="tag">العلامات</label>
												<input
													type="text"
													name="tag"
													id="tag"
													{...register(`tag`)}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card-body">
											<div className="form-item mt-3">
												<p
													className="bt bt-success btn-block secBtnForm"
													onClick={() => {
														tags.append({ value: getValues("tag") });
														resetField("tag");
													}}
												>
													اضافة العلامة
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-12" style={{ height: 50 }} />
									<div className="row">
										{tags.fields?.map((field, index) => {
											return (
												<Fragment key={v4()}>
													<div className="col-md-8">
														<div className="card-body">{field?.value}</div>
													</div>
													<div className="col-md-4">
														<div className="card-body">
															<div className="form-item mt-3">
																<div
																	className="bt bt-danger btn-block secBtnForm"
																	type="button"
																	onClick={() => tags.remove(index)}
																>
																	احذف تلك العلامة
																</div>
															</div>
														</div>
													</div>
												</Fragment>
											);
										})}
									</div>
								</div>
							</div>
							<div className="col-md-12" style={{ height: 50 }} />
							{faqs.fields?.map((field, index) => {
								return (
									<Fragment key={v4()}>
										<div className="col-md-8">
											<div className="card-body">
												<div className="form-item">
													<label htmlFor="questionAr">
														السؤال بالغة العربية
													</label>
													<input
														type="text"
														name="ar"
														id="questionAr"
														{...register(`faqs.${index}.question.ar`, {
															required: true,
														})}
													/>
												</div>
												<div className="form-item">
													<label htmlFor="questionEn">
														السؤال بالغة الانجليزية
													</label>
													<input
														type="text"
														name="en"
														id="questionEn"
														{...register(`faqs.${index}.question.en`, {
															required: true,
														})}
													/>
												</div>
												<div className="form-item">
													<label htmlFor="featureAr">
														الاجابة بالغة العربية
													</label>
													<textarea
														rows={5}
														type="text"
														name="ar"
														id="featureAr"
														{...register(`faqs.${index}.answer.ar`, {
															required: true,
														})}
													/>
												</div>
												<div className="form-item">
													<label htmlFor="featureEn">
														الاجابة بالغة الانجليزية
													</label>
													<textarea
														rows={5}
														type="text"
														name="en"
														id="featureEn"
														{...register(`faqs.${index}.answer.en`, {
															required: true,
														})}
													/>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card-body">
												<div className="form-item mt-3">
													<div
														className="bt bt-danger btn-block secBtnForm"
														type="button"
														onClick={() => faqs.remove(index)}
													>
														احذف ذلك الوصف
													</div>
												</div>
											</div>
										</div>
									</Fragment>
								);
							})}
							<div
								className="col-md-8"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								الرجاء اضافة سؤال واحد علي الاقل
							</div>
							<div className="col-md-4">
								<div className="card-body">
									<div className="form-item mt-3">
										<div
											className="bt bt-success btn-block secBtnForm"
											onClick={() => faqs.append({})}
										>
											اضافة سؤال
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12" style={{ height: 50 }} />

							<div className="col-md-12" style={{ height: 50 }} />
							<div className="col-md-12">
								<div className="form-item mt-3">
									<button className="bt bt-success btn-block">ارسال</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</section>
	);
};
export default BlogsShow;
