import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { Col, Row, Container } from "reactstrap";

const CommentsShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();

	const [workTime, setWorkTime] = useState({});

	const {
		params: { centerID },
	} = match;
	const dispatch = useDispatch();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة موعد جديد",
				breadcrumb: "مواعيد العمل",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(
				`${process.env.REACT_APP_API_URL}/pages/work-times/sections/${centerID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(response => {
				setWorkTime({ ...response.data.data.section });
			});
	};

	useEffect(() => {
		onStart();
	}, []);

	const handleSubmit = e => {
		e.preventDefault();

		axios
			.put(
				`${process.env.REACT_APP_API_URL}/pages/work-times/sections/${centerID}`,
				{
					section: {
						...workTime,
					},
				},
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(
				_ =>
					toast.success("تم تحديث الموعد بنجاح") && history.push("/times/index")
			)
			.catch(_ => toast.error("حدث خطا !"));
	};

	return (
		<section>
			<Container>
				<div className="card">
					<div className="card-body">
						<Row style={{ marginTop: "2rem" }}>
							<Col>
								<div className="form-item">
									<label htmlFor="dayAr">اليوم بالعربية</label>

									<input
										type="text"
										id="dayAr"
										name="dayAr"
										value={workTime?.day?.ar ?? ""}
										onChange={e => {
											setWorkTime({
												...workTime,
												day: { ...workTime.day, ar: e.target.value },
											});
										}}
									/>
								</div>
							</Col>

							<Col>
								<div className="form-item">
									<label htmlFor="dayEn"> اليوم بالانجليزية</label>

									<input
										type="text"
										id="dayEn"
										name="dayEn"
										value={workTime?.day?.en ?? ""}
										onChange={e => {
											setWorkTime({
												...workTime,
												day: { ...workTime.day, en: e.target.value },
											});
										}}
									/>
								</div>
							</Col>
						</Row>
						<Row>
							<Col>
								<div className="form-item">
									<label htmlFor="timeAr">الموعد بالعربية</label>

									<input
										type="text"
										id="timeAr"
										name="jobNameAR"
										value={workTime?.time?.ar ?? ""}
										onChange={e => {
											setWorkTime({
												...workTime,
												time: { ...workTime.time, ar: e.target.value },
											});
										}}
									/>
								</div>
							</Col>

							<Col>
								<div className="form-item">
									<label htmlFor="time"> الموعد بالانجليزية </label>

									<input
										type="text"
										id="time"
										name="jobNameEN"
										value={workTime?.time?.en ?? ""}
										onChange={e => {
											setWorkTime({
												...workTime,
												time: { ...workTime.time, en: e.target.value },
											});
										}}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col>
								<div className="form-item mt-3">
									<button
										className="bt bt-success btn-block"
										onClick={handleSubmit}
									>
										ارسال
									</button>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</Container>
		</section>
	);
};

export default CommentsShow;
