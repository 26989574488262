import React, { useEffect, useReducer, useState } from "react";
import Loader from "../../../components/shared/Loader";
import { set, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../../store/actions/metaAction";
import userPlaceholder from "../../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../../api/server";
import axios from "axios";
import { Select } from "../../../components/shared/FormComponents";

const LinksCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [socialTypes, setSocialTypes] = useState([]);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const [selectedType, setSelectedType] = useState(socialTypes[0]?.id);
	const [, forceUpdate] = useReducer(x => x + 1, 0);
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		setValue,
		getValues,
		reset,
	} = useForm();

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "اضافة فئة جديدة",
				breadcrumb: "الفئات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		server(cookie)
			.get("/settings/socialTypes")
			.then(data => {
				setSocialTypes([
					...data.data.data.socialTypes
						.filter(
							type =>
								!["phone", "email", "location address"].includes(type.name.en)
						)
						.map(type => ({
							...type,
							slug: type.id,
						}))
						.reverse(),
				]);
			});
	};

	// handleMethods
	const submitHandler = async data => {
		setLoading(true);
		try {
			const dataResponse = await server(cookie).post("/settings/socials", {
				socialType: data?.socialType,
				name: {
					ar: data?.nameAr,
					en: data?.nameEn,
				},
				link: data?.link,
			});
			reset();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="socialType">نوع الرابط</label>
									<Select
										id="socialType"
										name="socialType"
										required={true}
										options={[...socialTypes]}
										register={register}
										selectedValue={selectedType}
										onChange={e => {
											setSelectedType(e.target.value);
										}}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameAr">
										{selectedType !== "tTTyqRY0z"
											? "اسم وسيلة التواصل باللغة العربية"
											: "الموعيد بالغة العربية"}
									</label>
									<input
										type="text"
										name="name"
										id="nameAr"
										{...register("nameAr", {
											required: true,
										})}
									/>
								</div>
							</div>
							{/* "https://www.facebook.com/Ejadhcompan66w" */}
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">
										{selectedType !== "tTTyqRY0z"
											? "اسم وسيلة التواصل باللغة الانجليزية"
											: "الموعيد بالغة الانجليزية"}
									</label>
									<input
										type="text"
										name="name"
										id="nameEn"
										{...register("nameEn", {
											required: true,
										})}
									/>
								</div>
							</div>
							<div className="card-body">
								<div className="form-item">
									<label htmlFor="nameEn">
										{selectedType !== "tTTyqRY0z" ? "رابط التواصل" : "الموعيد"}
									</label>
									<input
										type="text"
										name="link"
										id="link"
										{...register("link", {
											required: true,
										})}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="form-item mt-3">
						<button className="bt bt-success btn-block">ارسال</button>
					</div>
				</div>
			</form>
		</section>
	);
};

export default LinksCreate;
