import React, { useRef } from "react";
import imageUpload from "../../assets/svgs/imageUpload.svg";
import removeImg from "../../assets/svgs/close.svg";
import { uploadServer } from "../../api/server";
import cookie from "js-cookie";
import axios from "axios";

const ImageUpload = ({
	title = "",
	page = "",
	selectedImage,
	setSelectedImage,
}) => {
	const formButton = useRef(null);
	const imageChange = async e => {
		formButton.current.disabled = true;
		formButton.current.innerText = "جاري الرفع...";
		if (e.target.files && e.target.files.length > 0) {
			const formData = new FormData();
			formData.append("file", e.target.files[0]);
			const { data } = await uploadServer(cookie).post("/upload", formData);
			setSelectedImage({
				preview: e.target.file,
				path: data.data.file.path,
			});
		}
		formButton.current.disabled = false;
		formButton.current.innerText = "حفظ";
	};

	const onUpload = e => {
		e.preventDefault();
		if (!selectedImage?.path) {
			return;
		}
		let image = `uploads/${selectedImage?.path?.split("/").at(-1)}`;
		axios.put(
			`${process.env.REACT_APP_API_URL}/pages/${page}`,
			{ image, title },
			{
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			}
		);
	};

	const removeSelectedImage = () => {
		setSelectedImage("");
	};

	const displayImage = url =>
		url.includes("http") ? url : process.env.REACT_APP_MAIN_URL + "/" + url;

	return (
		<div className="image-uploader image-contain">
			<label>
				<form onSubmit={onUpload}>
					{(selectedImage?.preview || selectedImage?.path) && (
						<div>
							<img
								src={
									selectedImage && selectedImage.preview
										? URL.createObjectURL(selectedImage.preview)
										: displayImage(selectedImage?.path)
										? displayImage(selectedImage?.path)
										: imageUpload
								}
								width={160}
								height={160}
								alt=""
							/>
							<button className="removeImg" onClick={removeSelectedImage}>
								<img src={removeImg} alt="" />
							</button>
						</div>
					)}
					<br />
					<input accept="image/*" type="file" onChange={imageChange} />
					<br />
					<br />
					<button ref={formButton} className="bt btn-info rounded text-white">
						حفظ
					</button>
				</form>
			</label>
		</div>
	);
};

export default ImageUpload;
