import React, { useEffect, useState } from "react";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";

import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Loader from "../../components/shared/Loader";
import { updateMeta } from "../../store/actions/metaAction";
import { v4 } from "uuid";
import ImageUpload from "../../components/shared/ImageUpload";

const CommentsIndex = () => {
	const auth = useSelector(state => state.auth.user);
	const [comments, setComments] = useState([]);
	const [commentsR, setCommentsR] = useState([]);
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	const [title, setTitle] = useState("");
	const [selectedImage, setSelectedImage] = useState({
		preview: null,
		path: "",
	});

	useEffect(() => {
		onStart();
	}, []);

	const onStart = () => {
		dispatch(
			updateMeta({
				title: "جميع التعليقات ",
				breadcrumb: "التعليقات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		axios
			.get(`${process.env.REACT_APP_API_URL}/pages/comments`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(function (response) {
				// console.log(response.data.data[0].categories[0].posts[0].data.opinions);
				setSelectedImage({
					path:
						process.env.REACT_APP_MAIN_URL +
						"/" +
						response?.data?.data?.page?.image,
				});
				setTitle(response?.data?.data?.page?.title);
				setComments([
					...response.data.data.page.sections.map(Comment => {
						return {
							...Comment,
							id: `${Comment.slug}${v4()}`,
							name: Comment?.name?.ar ?? "",
							position: Comment?.position?.ar ?? "",
						};
					}),
				]);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
				toast.error("خطأ، برجاء المحاولة لاحقا!");
			});
	};

	const columns = [
		{
			name: "job",
			selector: "position",
			sortable: true,
		},
		{
			name: "الاسم",
			selector: "name",
			sortable: true,
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link
							className="bt bt-sm bt-success"
							to={`/Comments/show/${row.slug}`}
						>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.slug)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = articleID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteArticle(articleID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteArticle = CommentID => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/pages/comments/sections/${CommentID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(_ => onStart() && toast.success("تم الحذف بنجاح"))
			.catch(_ => toast.error("حدث خطا!"));
	};

	return (
		<section>
			{loading && <Loader />}

			<div className="datatable">
				<DataTableExtensions columns={columns} data={comments}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
			<div>
				<div className="row">
					<div className="col">
						<div className="form-group required">
							<hr />
							<h5>اضافة وتعديل الخلفية</h5>
							<ImageUpload
								title={title}
								page="comments"
								selectedImage={selectedImage}
								setSelectedImage={e => {
									setSelectedImage(e);
									console.log("setSelectedImage", e);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CommentsIndex;
