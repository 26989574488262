import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server, { uploadServer } from "../../api/server";
import userPlaceholder from "../../assets/images/placeholder.jpg";

const BreadcrumbIndex = () => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	// const [data, setData] = useState([]);
	const [pictureImg, setPictureImg] = useState(userPlaceholder);
	const [data, setData] = useState({});

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "خلفية قسم العناوين",
				breadcrumb: "خلفية قسم العناوين",
				// button: {
				// 	show: true,
				// 	text: "اضافة راية ترحيبية جديدة",
				// 	link: "/siteinfo/heroSection/create",
				// },
			})
		);
		try {
			const pageData = await server(cookie).get("/pages/breadcrumb-background");

			setPictureImg(pageData.data.data.page.image);
			setData(pageData.data.data.page);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	// const handleDelete = postID => {
	// 	axios
	// 		.delete(
	// 			`${process.env.REACT_APP_API_URL}/pages/hero-section/sections/${postID}`,
	// 			{
	// 				headers: {
	// 					Authorization: `Bearer ${cookie.get("token")}`,
	// 					Accept: "application/json",
	// 				},
	// 			}
	// 		)
	// 		.then(function (response) {
	// 			onStart();
	// 		})
	// 		.catch(function (error) {
	// 			toast.error("خطأ، برجاء المحاولة لاحقا!");
	// 		});
	// };

	useEffect(() => {
		onStart();
	}, []);

	const handlePicture = e => {
		const tempValue = e?.target?.files?.[0];
		// if (tempValue?.length < 1) return;
		// const blobUrl = URL?.createObjectURL(tempValue);
		// setValue("picturePath", blobUrl);
		console.log("tempValue", tempValue);
		setPictureImg(tempValue);
	};

	const submitHandler = async () => {
		setLoading(true);
		try {
			const imageResponse = async () => {
				if (pictureImg.length <= 0) return pictureImg;
				const dataForm = new FormData();
				dataForm.append("file", pictureImg);
				const imageData = await server(cookie).post(`/upload`, dataForm);

				// uploadServer(cookie).post("/upload", dataForm);
				return imageData?.data?.data?.file?.path;
			};
			const imagePath = await imageResponse();
			// const { picturePath, pictureUrl, ...finalData } = {
			// 	...data,
			// 	image: imageData,
			// };
			// console.log(finalData);
			// const dataResponse =
			await server(cookie).put(`/pages/breadcrumb-background/`, {
				...data,
				image: imagePath,
			});
			// reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};
	console.log(
		"pictureImg?.size",
		pictureImg?.size
			? URL?.createObjectURL(pictureImg)
			: `${process.env.REACT_APP_MAIN_URL}pictureImg`
	);
	return (
		<section>
			{loading && <Loader />}
			<section>
				<div className="row">
					<div className="card-body">
						<div className="form-item">
							<label htmlFor="picture">البانر</label>
							<input
								type="file"
								id="picture"
								onChange={e => handlePicture(e)}
								// {...register("pictureUrl", {
								// onChange: () => handlePicture(),
								// })}
							/>
							<img
								style={{ width: "200px", height: "200px" }}
								src={
									pictureImg?.size
										? URL?.createObjectURL(pictureImg)
										: `${process.env.REACT_APP_MAIN_URL}/${pictureImg}`
								}
								alt="avatar"
								className="imagePreview"
							/>
						</div>
					</div>
					<div className="form-item mt-3">
						<button className="bt bt-success btn-block" onClick={submitHandler}>
							حفظ
						</button>
					</div>
					{/* {data.map(post => (
						<div className="col-md-3" key={post.slug}>
							<div className="serviceItem">
								<button onClick={() => handleDelete(post.slug)}>
									<i className="fas fa-trash"></i>
								</button>
								<Link to={`/siteinfo/heroSection/show/${post.slug}`}>
									<img
										src={`${process.env.REACT_APP_MAIN_URL}/${post.image}`}
										alt={post.sectionTitle.ar ?? ""}
									/>
									<h1>{post.sectionTitle?.ar ?? ""}</h1>
									<p>{post.description?.ar ?? ""}</p>
								</Link>
							</div>
						</div>
					))} */}
				</div>
			</section>
		</section>
	);
};
export default BreadcrumbIndex;
