import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server from "../../api/server";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { confirmAlert } from "react-confirm-alert";

const BlogsIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "المدونة",
				breadcrumb: "المدوانات",
				button: {
					show: true,
					text: "اضافة مدونة جديدة",
					link: "/blogs/create",
				},
			})
		);

		try {
			const pageData = await server(cookie).get("/blogs");
			setData(pageData.data.data.blogs);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	const columns = [
		{
			name: "الاسم",
			selector: "title.ar",
			sortable: true,
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link className="bt bt-sm bt-success" to={`/blogs/show/${row.id}`}>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.id)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = articleID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteArticle(articleID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteArticle = CommentID => {
		axios
			.delete(`${process.env.REACT_APP_API_URL}/blogs/${CommentID}`, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
				},
			})
			.then(_ => onStart() && toast.success("تم الحذف بنجاح"))
			.catch(_ => toast.error("حدث خطا!"));
	};

	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<div className="datatable">
				<DataTableExtensions columns={columns} data={data}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default BlogsIndex;
