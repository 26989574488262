import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server from "../../api/server";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { confirmAlert } from "react-confirm-alert";

const ProjectIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();
	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "جميع المشاريع",
				breadcrumb: "المشاريع",
				button: {
					show: true,
					text: "اضافة مشروع جديدة",
					link: "/projects/create",
				},
			})
		);

		try {
			const pageData = await server(cookie).get("/pages/project");
			setData(pageData?.data?.data?.page?.sections);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	const columns = [
		{
			name: "الاسم",
			selector: "name.ar",
			sortable: true,
		},
		// {
		// 	name: "",
		// 	selector: "settings",
		// 	cell: row => (
		// 		<ul className="list-inline settingsList">
		// 			<li className="list-inline-item">
		// 				<Link
		// 					className="bt bt-sm bt-success"
		// 					to={`/projects/show/${row.slug}`}
		// 				>
		// 					<i className="fas fa-edit"></i>
		// 				</Link>
		// 			</li>
		// 			<li className="list-inline-item">
		// 				<button
		// 					className="bt bt-sm bt-danger"
		// 					onClick={() => handleConfrimDelete(row.slug)}
		// 				>
		// 					<i className="fas fa-trash"></i>
		// 				</button>
		// 			</li>
		// 		</ul>
		// 	),
		// },

		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					{row.category === "" || row.category == null ? (
						<li className="list-inline-item">
							<span className="badge badge-warning">لم يتم تحديد الفئة</span>
						</li>
					) : null}
					<li className="list-inline-item">
						<Link
							className="bt bt-sm bt-success"
							to={`/projects/show/${row.slug}`}
						>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.slug)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = articleID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteArticle(articleID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteArticle = CommentID => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/pages/project/sections/${CommentID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(_ => onStart() && toast.success("تم الحذف بنجاح"))
			.catch(_ => toast.error("حدث خطا!"));
	};

	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}
			{console.log(data)}
			<div className="datatable">
				<DataTableExtensions columns={columns} data={data}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
		</section>
	);
};

export default ProjectIndex;
