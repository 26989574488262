import React, { Fragment, useEffect, useReducer, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../api/server";
import ReactPlayer from "react-player";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Input, Select } from "../../components/shared/FormComponents";
import { toast } from "react-toastify";
import { v4 } from "uuid";

const ProjectShow = ({ match }) => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		params: { projectId },
	} = match;
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		reset,
	} = useForm();
	const {
		fields: featureFields,
		append: featureAppend,
		remove: featureRemove,
	} = useFieldArray({
		control,
		name: "features",
	});

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	// initial states
	const [category, setCategory] = useState([]);

	// form Needed states
	const [imageArray, setImageArray] = useState([]);
	const [files, setFiles] = useState([]);
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	const onStart = async () => {
		setLoading(true);
		dispatch(
			updateMeta({
				title: "اضافة مشروع جديدة",
				breadcrumb: "المشاريع",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);
		await Promise.all([
			await server(cookie).get("/pages/categories"),
			await server(cookie).get(`/pages/project/sections/${projectId}`),
		])
			.then(data => {
				const temp = data[1].data.data.section;
				for (const key in temp) {
					if (Object.hasOwnProperty.call(temp, key)) {
						const element = temp[key];
						if (key === "features") {
							setValue(
								"features",
								element?.items?.map(item => ({ ...item, iconPath: item?.icon }))
							);
							setValue("featureDescription", element?.description);
							continue;
						}
						if (key === "images") {
							setImageArray([...element]);
							continue;
						}
						setValue(key, element);
					}
				}
				setCategory([
					...data[0].data.data.page.sections.map(data => ({
						name: data.name.ar,
						slug: data.slug,
					})),
				]);
			})
			.catch(error => toast.error(error))
			.finally(() => forceUpdate());
		setLoading(false);
	};

	// render methods
	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	// handleMethods

	const handleAddFeature = () => {
		if (featureFields.length + 1 > 6) {
			toast.info("اقص عداد للمميزات هو 6 ميزات");
			return;
		}
		featureAppend({ icon: userPlaceholder });
	};

	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
		setValue("images", arr);
	};
	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(responseImage.data.data.file.path);
					setImageArray(imageArray);
					setValue("images", [imageArray[0]]);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};
	const mainHandleImageUpload = async (element, setter) => {
		setLoading(true);
		const formData = new FormData();
		formData.append(`file`, element.target.files[0]);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setter(responseImage.data.data.file.path);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});

		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};
	const handlePicture = async () => {
		const tempValue = getValues(`pictureUrl`);
		if (tempValue.length < 1) return;
		setLoading(true);
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		const formData = new FormData();
		formData.append(`file`, tempValue?.[0]);
		setLoading(false);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setValue(`mainImage`, responseImage.data.data.file.path);
				setValue(`pictureUrl`, blobUrl);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
		forceUpdate();
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};
	const handleFeaturePicture = async index => {
		const tempValue = getValues(`features.${index}.icon`);
		if (tempValue.length < 1) return;
		setLoading(true);
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		const formData = new FormData();
		formData.append(`file`, tempValue?.[0]);
		setLoading(false);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setValue(
					`features.${index}.iconPath`,
					responseImage.data.data.file.path
				);
				setValue(`features.${index}.icon`, blobUrl);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
		forceUpdate();
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const submitHandler = async data => {
		setLoading(true);
		try {
			const { pictureUrl, featureDescription, ...finalData } = {
				...data,
				features: {
					description: data?.featureDescription,
					items: [
						...data?.features.map(({ iconPath, icon, ...feature }) => ({
							...feature,
							icon: iconPath,
						})),
					],
				},
				title: `${data?.name.en} project`,
				images: [...imageArray],
			};

			const dataResponse = await server(cookie).put(
				`/pages/project/sections/${projectId}`,
				{
					section: finalData,
				}
			);
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
		register("mainImage", { required: true });
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<form onSubmit={handleSubmit(submitHandler)}>
				<div className="row">
					<div className="col-md-12">
						<div className="card">
							<div className="row">
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="nameAr">اسم المشروع باللغة العربية</label>
											<Input
												type="text"
												name="name.ar"
												id="nameAr"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="nameEn">
												اسم المشروع بالغة الانجليزية
											</label>
											<Input
												type="text"
												name="name.en"
												id="nameEn"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-12">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="description.ar">
												وصف المشروع باللغة العربية
											</label>
											<textarea
												rows={5}
												type="text"
												name="description"
												id="description.ar"
												{...register(`description.ar`, { required: true })}
											/>
										</div>
									</div>
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="description.en">
												وصف المشروع باللغة الانجليزية
											</label>
											<textarea
												rows={5}
												type="text"
												name="description"
												id="description.en"
												{...register(`description.en`, { required: true })}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="category">اختار الفئة</label>
											<Select
												name="category"
												id="category"
												required={true}
												options={[...category]}
												register={register}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="agent">اسم الوكيل</label>
											<Input
												type="text"
												name="agent"
												id="agent"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="locationAr">
												مكان المشروع بالغة العربية
											</label>
											<Input
												type="text"
												name="location.ar"
												id="locationAr"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="locationEn">
												مكان المشروع بالغة الانجليزية
											</label>
											<Input
												type="text"
												name="location.en"
												id="locationEn"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>

								<div className="col-md-6">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="date">تاريخ الاعلان</label>
											<Input
												type="date"
												name="date"
												id="date"
												register={register}
												required={true}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-12" style={{ height: 50 }} />
					<div className="col-md-12">
						<div className="card">
							<div className="card-body">
								<div className="form-item">
									<div className="dropzoneContainer">
										<div {...getRootProps({ className: "dropzone" })}>
											<input {...getInputProps()} />
											<p>اضغط هنا لرفع الصورة الداخلية</p>
										</div>
										<aside className="thumbsContainer">{thumbs}</aside>
										<button
											onClick={handleImageUpload}
											type="button"
											className="bt bt-success"
										>
											رفع الصورة
										</button>
									</div>
									<div className="row">
										{imageArray.map(img => (
											<div className="col-md-2" key={img}>
												<div className="image-preview">
													<img
														src={`${process.env.REACT_APP_MAIN_URL}/${img}`}
														alt={img}
														className="imgList"
													/>
													<button onClick={e => deleteImage(e, img)}>
														<i className="fas fa-trash"></i>
													</button>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					<div className="col-md-8">
						<div className="form-item">
							<label htmlFor="featureDescription.ar">
								وصف المميزات باللغة العربية
							</label>
							<textarea
								rows={5}
								type="text"
								name="featureDescription"
								id="featureDescription.ar"
								{...register(`featureDescription.ar`)}
							/>
						</div>
						<div className="form-item">
							<label htmlFor="featureDescription.en">
								وصف المميزات باللغة الانجليزية
							</label>
							<textarea
								rows={5}
								type="text"
								name="featureDescription"
								id="featureDescription.en"
								{...register(`featureDescription.en`)}
							/>
						</div>
					</div>
					<div className="col-md-4">
						<div className="card-body">
							<div className="form-item">
								<label htmlFor="picture">البانر</label>
								<input
									type="file"
									id="picture"
									{...register("pictureUrl", {
										onChange: () => handlePicture(),
									})}
								/>
								<img
									src={
										getValues("mainImage") == null ||
										getValues("mainImage") === ""
											? userPlaceholder
											: `${process.env.REACT_APP_MAIN_URL}/${getValues(
													"mainImage"
											  )}`
									}
									alt="avatar"
									className="imagePreview"
								/>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					{featureFields?.map((field, index) => {
						return (
							<Fragment key={v4()}>
								<div className="col-md-8">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="featureAr">ميزة بالغة العربية</label>
											<input
												type="text"
												name="ar"
												id="featureAr"
												{...register(`features.${index}.title.ar`)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor="featureEn">ميزة بالغة الانجليزية</label>
											<input
												type="text"
												name="en"
												id="featureEn"
												{...register(`features.${index}.title.en`)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor={`features.${index}.description.ar`}>
												وصف الميزة باللغة العربية
											</label>
											<textarea
												rows={5}
												type="text"
												name="features.description"
												id={`features.${index}.description.ar`}
												{...register(`features.${index}.description.ar`)}
											/>
										</div>
										<div className="form-item">
											<label htmlFor={`features.${index}.description.en`}>
												وصف الميزة باللغة الانجليزية
											</label>
											<textarea
												rows={5}
												type="text"
												name="features.description"
												id={`features.${index}.description.en`}
												{...register(`features.${index}.description.en`)}
											/>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="card-body">
										<div className="form-item">
											<label htmlFor="featureIcon">ايكون الميزة</label>
											<input
												type="file"
												id="featureIcon"
												{...register(`features.${index}.icon`, {
													onChange: () => handleFeaturePicture(index),
												})}
											/>
											<input
												className="hiddenInput"
												type="text"
												name="iconPath"
												id="featureEn"
												{...register(`features.${index}.iconPath`)}
											/>
											<img
												src={
													getValues(`features.${index}.icon`) ===
													userPlaceholder
														? userPlaceholder
														: `${process.env.REACT_APP_MAIN_URL}/${getValues(
																`features.${index}.iconPath`
														  )}`
												}
												alt="avatar"
												className="imagePreview"
											/>
										</div>
										<div className="card-body">
											<div className="form-item mt-3">
												<div
													className="bt bt-danger btn-block secBtnForm"
													type="button"
													onClick={() => featureRemove(index)}
												>
													احذف تلك الميزة
												</div>
											</div>
										</div>
									</div>
								</div>
							</Fragment>
						);
					})}
					<div
						className="col-md-8"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						الرجاء اضافة ميزة واحدة للعقار علي الاقل
					</div>
					<div className="col-md-4">
						<div className="card-body">
							<div className="form-item mt-3">
								<div
									className="bt bt-success btn-block secBtnForm"
									onClick={handleAddFeature}
								>
									اضافة الميزة
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-12" style={{ height: 50 }} />
					<div className="col-md-12">
						<div className="form-item mt-3">
							<button className="bt bt-success btn-block">ارسال</button>
						</div>
					</div>
				</div>

				{/* array values */}
				<input
					type="checkbox"
					className="hiddenInput"
					{...register("images")}
				/>
			</form>
		</section>
	);
};
export default ProjectShow;
