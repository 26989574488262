import React, { useEffect, useState } from "react";
import Loader from "../../components/shared/Loader";
import { Link } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { toast } from "react-toastify";
import { updateMeta } from "../../store/actions/metaAction";
import { useDispatch } from "react-redux";
import server from "../../api/server";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { confirmAlert } from "react-confirm-alert";
import ImageUpload from "../../components/shared/ImageUpload";

const ServicesIndex = () => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const dispatch = useDispatch();

	const [title, setTitle] = useState("");
	const [selectedImage, setSelectedImage] = useState({
		preview: null,
		path: "",
	});

	const onStart = async () => {
		dispatch(
			updateMeta({
				title: "جميع الخدمات",
				breadcrumb: "الخدمات",
				button: {
					show: true,
					text: "اضافة مشروع جديدة",
					link: "/services/create",
				},
			})
		);

		try {
			const pageData = await server(cookie).get("/pages/our-services-4");
			setData(pageData?.data?.data?.page?.sections);
			setSelectedImage({
				path:
					process.env.REACT_APP_MAIN_URL +
					"/" +
					pageData?.data?.data?.page?.image,
			});
			setTitle(pageData?.data?.data?.page?.title);
			setLoading(false);
		} catch (error) {
			toast.error("خطأ، برجاء المحاولة لاحقا!");
			setLoading(false);
		}
	};

	const columns = [
		{
			name: "الاسم",
			selector: row => row?.name?.ar ?? row?.name?.ar,
			sortable: true,
		},
		{
			name: "",
			selector: "settings",
			cell: row => (
				<ul className="list-inline settingsList">
					<li className="list-inline-item">
						<Link
							className="bt bt-sm bt-success"
							to={`/services/show/${row.slug}`}
						>
							<i className="fas fa-edit"></i>
						</Link>
					</li>
					<li className="list-inline-item">
						<button
							className="bt bt-sm bt-danger"
							onClick={() => handleConfrimDelete(row.slug)}
						>
							<i className="fas fa-trash"></i>
						</button>
					</li>
				</ul>
			),
		},
	];

	const handleConfrimDelete = articleID => {
		confirmAlert({
			title: "تأكيد الحذف",
			message: "هل أنت متأكد من رغبتك في الحذف؟",
			buttons: [
				{
					label: "نعم",
					onClick: () => deleteArticle(articleID),
				},
				{
					label: "لا",
					onClick: () => console.log("No"),
				},
			],
		});
	};

	const deleteArticle = CommentID => {
		axios
			.delete(
				`${process.env.REACT_APP_API_URL}/pages/our-services-4/sections/${CommentID}`,
				{
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
					},
				}
			)
			.then(_ => onStart() && toast.success("تم الحذف بنجاح"))
			.catch(_ => toast.error("حدث خطا!"));
	};

	useEffect(() => {
		onStart();
	}, []);

	return (
		<section>
			{loading && <Loader />}

			<div className="datatable">
				<DataTableExtensions columns={columns} data={data}>
					<DataTable
						noHeader
						defaultSortField="id"
						defaultSortAsc={false}
						pagination
						highlightOnHover
						direction="rtl"
						striped
					/>
				</DataTableExtensions>
			</div>
			<div>
				<div className="row">
					<div className="col">
						<div className="form-group required">
							<hr />
							<h5>اضافة وتعديل الخلفية</h5>
							<ImageUpload
								title={title}
								page="our-services-4"
								selectedImage={selectedImage}
								setSelectedImage={e => {
									setSelectedImage(e);
									console.log("setSelectedImage", e);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ServicesIndex;
