import React, { Fragment, useEffect, useReducer, useState } from "react";
import Loader from "../../components/shared/Loader";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateMeta } from "../../store/actions/metaAction";
import userPlaceholder from "../../assets/images/placeholder.jpg";
import cookie from "js-cookie";
import server, { uploadServer } from "../../api/server";
import ReactPlayer from "react-player";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Input, Select } from "../../components/shared/FormComponents";
import { toast } from "react-toastify";
import { v4 } from "uuid";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";

const ServicesCreate = () => {
	const auth = useSelector(state => state.auth.user);
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const {
		control,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues,
		reset,
		resetField,
	} = useForm({
		defaultValues: {
			faqs: [],
		},
	});
	const faqs = useFieldArray({
		control,
		name: "faqs",
	});
	const links = useFieldArray({
		control,
		name: "urls",
	});

	const [files, setFiles] = useState([]);
	const [imageArray, setImageArray] = useState([]);

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*",
		multiple: false,
		onDrop: acceptedFiles => {
			setFiles(
				acceptedFiles.map(file =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					})
				)
			);
		},
	});

	// form Needed states
	const [, forceUpdate] = useReducer(x => x + 1, 0);

	const onStart = async () => {
		setLoading(true);
		dispatch(
			updateMeta({
				title: "اضافة خدمة جديدة",
				breadcrumb: "الخدمات",
				button: {
					show: false,
					text: null,
					link: null,
				},
			})
		);

		setLoading(false);
	};

	// render methods
	const thumbs = files.map(file => (
		<div className="thumb" key={file.name}>
			<div className="thumbInner">
				<img src={file.preview} className="img" />
			</div>
		</div>
	));

	// handleMethods
	const deleteImage = (event, img) => {
		event.preventDefault();
		const arr = imageArray.filter(item => {
			return item !== img;
		});
		setImageArray(arr);
		setValue("images", arr);
	};
	const handleImageUpload = async e => {
		if (!files.length) {
			toast.error("برجاء إختيار صورة اولا");
			return;
		}
		e.preventDefault();
		setLoading(true);
		for (let i = 0; i < files.length; i++) {
			const formData = new FormData();
			formData.append(`file`, files[i]);
			await axios
				.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
					headers: {
						Authorization: `Bearer ${cookie.get("token")}`,
						Accept: "application/json",
						"Content-Type": "multipart/form-data",
					},
				})
				.then(function (responseImage) {
					imageArray.push(responseImage.data.data.file.path);
					setImageArray(imageArray);
					setValue("images", [...imageArray]);
				})
				.catch(function (error) {
					toast.error("خطأ، برجاء المحاولة مره اخرى!");
					setLoading(false);
				});
		}
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};
	const handlePicture = async () => {
		const tempValue = getValues(`pictureUrl`);
		if (tempValue.length < 1) return;
		setLoading(true);
		const blobUrl = URL?.createObjectURL(tempValue?.[0]);
		const formData = new FormData();
		formData.append(`file`, tempValue?.[0]);
		setLoading(false);
		await axios
			.post(`${process.env.REACT_APP_API_URL}/upload`, formData, {
				headers: {
					Authorization: `Bearer ${cookie.get("token")}`,
					Accept: "application/json",
					"Content-Type": "multipart/form-data",
				},
			})
			.then(function (responseImage) {
				setValue(`icon`, responseImage.data.data.file.path);
				setValue(`pictureUrl`, blobUrl);
			})
			.catch(function (error) {
				toast.error("خطأ، برجاء المحاولة مره اخرى!");
				setLoading(false);
			});
		forceUpdate();
		setLoading(false);
		toast.success("تم رفع الصور بنجاح!");
	};

	const submitHandler = async data => {
		setLoading(true);
		try {
			const { pictureUrl, link, ...finalData } = {
				...data,
				title: `${data?.name?.en} service`,
				urls: data?.urls.map(link => link?.value),
			};
			const dataResponse = await server(cookie).post(
				"/pages/our-services-4/sections/",
				{
					section: finalData,
				}
			);
			reset();
			onStart();
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	// watchers
	useEffect(() => {
		onStart();
		register("icon", { required: true });
	}, []);

	return (
		<section>
			{loading && <Loader />}
			<div className="card">
				<form onSubmit={handleSubmit(submitHandler)}>
					<div className="row">
						<div className="row">
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="nameAr">اسم باللغة العربية</label>
										<Input
											type="text"
											name="name.ar"
											id="nameAr"
											register={register}
											required={true}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="nameEn">اسم بالغة الانجليزية</label>
										<Input
											type="text"
											name="name.en"
											id="nameEn"
											register={register}
											required={true}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="descriptionAr">الوصف بالغة العربية</label>
										<textarea
											rows={5}
											type="text"
											name="descriptionAr"
											id="descriptionAr"
											{...register(`description.ar`, { required: true })}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="descriptionEn">
											الوصف بالغة الانجليزية
										</label>
										<textarea
											rows={5}
											type="text"
											name="descriptionEn"
											id="descriptionEn"
											{...register(`description.en`, { required: true })}
										/>
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="card">
									<div className="card-body">
										<div className="form-item">
											<div className="dropzoneContainer">
												<div {...getRootProps({ className: "dropzone" })}>
													<input {...getInputProps()} />
													<p>اضغط هنا لرفع الصورة الداخلية</p>
												</div>
												<aside className="thumbsContainer">{thumbs}</aside>
												<button
													onClick={handleImageUpload}
													type="button"
													className="bt bt-success"
												>
													رفع الصورة
												</button>
											</div>
											<div className="row">
												{imageArray.map(img => (
													<div className="col-md-2" key={img}>
														<div className="image-preview">
															<img
																src={`${process.env.REACT_APP_MAIN_URL}/${img}`}
																alt={img}
																className="imgList"
															/>
															<button onClick={e => deleteImage(e, img)}>
																<i className="fas fa-trash"></i>
															</button>
														</div>
													</div>
												))}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-8">
								<div className="card-body">
									<div className="form-item">
										<label htmlFor="picture">الايقونة</label>
										<input
											type="file"
											id="picture"
											{...register("pictureUrl", {
												onChange: () => handlePicture(),
											})}
										/>
										<img
											src={
												getValues("icon") == null || getValues("icon") === ""
													? userPlaceholder
													: `${process.env.REACT_APP_MAIN_URL}/${getValues(
															"icon"
													  )}`
											}
											alt="avatar"
											className="imagePreview"
										/>
									</div>
								</div>
							</div>

							<div className="col-md-12" style={{ height: 50 }} />
							{faqs.fields?.map((field, index) => {
								return (
									<Fragment key={v4()}>
										<div className="col-md-8">
											<div className="card-body">
												<div className="form-item">
													<label htmlFor="questionAr">
														السؤال بالغة العربية
													</label>
													<input
														type="text"
														name="ar"
														id="questionAr"
														{...register(`faqs.${index}.question.ar`, {
															required: true,
														})}
													/>
												</div>
												<div className="form-item">
													<label htmlFor="questionEn">
														السؤال بالغة الانجليزية
													</label>
													<input
														type="text"
														name="en"
														id="questionEn"
														{...register(`faqs.${index}.question.en`, {
															required: true,
														})}
													/>
												</div>
												<div className="form-item">
													<label htmlFor="featureAr">
														الاجابة بالغة العربية
													</label>
													<textarea
														rows={5}
														type="text"
														name="ar"
														id="featureAr"
														{...register(`faqs.${index}.answer.ar`, {
															required: true,
														})}
													/>
												</div>
												<div className="form-item">
													<label htmlFor="featureEn">
														الاجابة بالغة الانجليزية
													</label>
													<textarea
														rows={5}
														type="text"
														name="en"
														id="featureEn"
														{...register(`faqs.${index}.answer.en`, {
															required: true,
														})}
													/>
												</div>
											</div>
										</div>
										<div className="col-md-4">
											<div className="card-body">
												<div className="form-item mt-3">
													<div
														className="bt bt-danger btn-block secBtnForm"
														type="button"
														onClick={() => faqs.remove(index)}
													>
														احذف ذلك الوصف
													</div>
												</div>
											</div>
										</div>
									</Fragment>
								);
							})}
							<div
								className="col-md-8"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								الرجاء اضافة سؤال واحد علي الاقل
							</div>
							<div className="col-md-4">
								<div className="card-body">
									<div className="form-item mt-3">
										<div
											className="bt bt-success btn-block secBtnForm"
											onClick={() => faqs.append({})}
										>
											اضافة سؤال
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-12" style={{ height: 50 }} />
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-8">
										<div className="card-body">
											<div className="form-item">
												<label htmlFor="link">الرابط</label>
												<input
													type="text"
													name="link"
													id="link"
													{...register(`link`)}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card-body">
											<div className="form-item mt-3">
												<p
													className="bt bt-success btn-block secBtnForm"
													onClick={() => {
														links.append({ value: getValues("link") });
														resetField("link");
													}}
												>
													اضافة العلامة
												</p>
											</div>
										</div>
									</div>
									<div className="col-md-12" style={{ height: 50 }} />
									<div className="row">
										{links.fields?.map((field, index) => {
											return (
												<Fragment key={v4()}>
													<div className="col-md-8">
														<div className="card-body">{field?.value}</div>
													</div>
													<div className="col-md-4">
														<div className="card-body">
															<div className="form-item mt-3">
																<div
																	className="bt bt-danger btn-block secBtnForm"
																	type="button"
																	onClick={() => links.remove(index)}
																>
																	احذف ذلك الرابط
																</div>
															</div>
														</div>
													</div>
												</Fragment>
											);
										})}
									</div>
								</div>
							</div>
							<div className="col-md-12" style={{ height: 50 }} />
							<div className="col-md-12">
								<div className="form-item mt-3">
									<button className="bt bt-success btn-block">ارسال</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</section>
	);
};
export default ServicesCreate;
