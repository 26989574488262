import * as types from "../types";

const initialState = {
	title: null,
	breadcrumb: null,
	button: {
		permissions: [],
		show: false,
		text: null,
		link: null,
	},
};

export const metaReducer = (state = initialState, action) => {
	switch (action.type) {
		case types.UPDATE_META:
			return {
				...state,
				title: action.payload.title,
				breadcrumb: action.payload.breadcrumb,
				button: {
					permissions: action?.payload?.button?.permissions,
					show: action?.payload?.button?.show,
					text: action?.payload?.button?.text,
					link: action?.payload?.button?.link,
				},
			};
		default:
			return state;
	}
};
